import React, { FC, Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import { mq, rem } from '@/utils/helpers'
import ThemeContext from '@/context/Theme'
import { Theme } from '@/utils/themes'
import fonts from '@/utils/fonts'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import Card2 from '@/components/snippets/Card2'
import Typography from '@/components/typography'
import Icon from '@/components/snippets/Icon'
import breakpoints from '@/utils/breakpoints'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Case: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="black.5" theme={theme} {...others}>
      <Container
        responsive={{
          default: 2,
          md: 1,
          sm: 2
        }}
      >
        <Heading>
          賃貸管理業務なら
          <br />
          なんでもご依頼ください。
        </Heading>
        <List>
          <List_Inner>
            <List_Item>
              <Heading2>
                <Heading2_Order>
                  <Heading2_En
                    variant="h4"
                    lang="en"
                    component="span"
                    responsive={{
                      sm: 'h6'
                    }}
                  >
                    CASE
                  </Heading2_En>
                  <Heading2_Number>01</Heading2_Number>
                </Heading2_Order>
                <Heading2_Text
                  variant="h3"
                  component="span"
                  responsive={{
                    md: 'h4',
                    sm: 'h5'
                  }}
                >
                  入力業務
                </Heading2_Text>
              </Heading2>
              <Order>
                <Card2
                  css={styles.Card2}
                  title="BEFORE"
                  color="secondary"
                  image={
                    <StaticImage
                      src="./images/case-2-before.png"
                      alt=""
                      placeholder="none"
                      quality={100}
                      loading="eager"
                    />
                  }
                  text={
                    <Fragment>
                      管理戸数が増えるのは良いが、
                      <br className="v-gt_md" />
                      <strong>入力業務が間に合わない</strong>
                    </Fragment>
                  }
                />
                <Arrow>
                  <Icon css={styles.Icon}>right-arrow</Icon>
                </Arrow>
                <Card2
                  css={styles.Card2}
                  title="AFTER"
                  color="primary"
                  image={
                    <StaticImage
                      src="./images/case-2-after.png"
                      alt=""
                      placeholder="none"
                      quality={100}
                      loading="eager"
                    />
                  }
                  text={
                    <Fragment>
                      業者間流通サイト、賃貸管理システム、ポータルサイト、エクセル、ワード、
                      <strong>どんなものでも入力可能</strong>です。
                    </Fragment>
                  }
                />
              </Order>
            </List_Item>
            <List_Item>
              <Heading2>
                <Heading2_Order>
                  <Heading2_En
                    variant="h4"
                    lang="en"
                    component="span"
                    responsive={{
                      sm: 'h6'
                    }}
                  >
                    CASE
                  </Heading2_En>
                  <Heading2_Number>02</Heading2_Number>
                </Heading2_Order>
                <Heading2_Text
                  variant="h3"
                  component="span"
                  responsive={{
                    md: 'h4',
                    sm: 'h5'
                  }}
                >
                  解約業務
                </Heading2_Text>
              </Heading2>
              <Order>
                <Card2
                  css={styles.Card2}
                  title="BEFORE"
                  color="secondary"
                  image={
                    <StaticImage
                      src="./images/case-3-before.png"
                      alt=""
                      placeholder="none"
                      quality={100}
                      loading="eager"
                    />
                  }
                  text={
                    <Fragment>
                      解約の受付、原状回復工事の発注と
                      <br />
                      <strong>やることがたくさん</strong>
                    </Fragment>
                  }
                />
                <Arrow>
                  <Icon css={styles.Icon}>right-arrow</Icon>
                </Arrow>
                <Card2
                  css={styles.Card2}
                  title="AFTER"
                  color="primary"
                  image={
                    <StaticImage
                      src="./images/case-3-after.png"
                      alt=""
                      placeholder="none"
                      quality={100}
                      loading="eager"
                    />
                  }
                  text={
                    <Fragment>
                      解約業務の<strong>全てを外注可能</strong>です。
                    </Fragment>
                  }
                />
              </Order>
            </List_Item>
          </List_Inner>
        </List>
      </Container>
    </Root>
  )
}

export default Case

const Root = styled(Section)<StyleProps>``

const List = styled.div`
  margin-top: ${rem(64)};
  @media ${mq.and(breakpoints.md)} {
    margin-top: ${rem(48)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(48)};
  }
`

const List_Inner = styled.ol``

const List_Item = styled.li`
  margin-top: ${rem(64)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(48)};
  }
  &:nth-of-type(1) {
    margin-top: 0;
  }
`

const Order = styled.div`
  @media ${mq.and(breakpoints.gt_sm)} {
    margin-top: ${rem(26)};
    display: flex;
  }
  margin-top: ${rem(16)};
`

const Heading2 = styled.h3`
  display: flex;
  align-items: center;
`

const Heading2_Order = styled.div``

const Heading2_En = styled(Typography)`
  position: relative;
  bottom: ${rem(12)};
  display: inline-block;
  margin-right: ${rem(8)};
  padding-bottom: ${rem(4)};
  border-bottom: ${rem(4)} solid currentColor;
  @media ${mq.and(breakpoints.sm)} {
    bottom: ${rem(10)};
    padding-bottom: ${rem(2)};
  }
`

const Heading2_Number = styled.span`
  font-size: ${rem(80)};
  font-family: ${fonts.en};
  font-weight: bold;
  letter-spacing: 0.04em;
  @media ${mq.and(breakpoints.md)} {
    font-size: ${rem(64)};
  }
  @media ${mq.and(breakpoints.sm)} {
    font-size: ${rem(40)};
  }
`

const Heading2_Text = styled(Typography)`
  margin-left: ${rem(24)};
  @media ${mq.and(breakpoints.sm)} {
    margin-left: ${rem(8)};
  }
`

const Arrow = styled.div`
  margin: 0 ${rem(20)};
  font-size: ${rem(40)};
  display: flex;
  align-items: center;
  @media ${mq.and(breakpoints.md)} {
    margin: 0 ${rem(12)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin: ${rem(12)} 0;
    justify-content: center;
  }
`

const styles = {
  Card2: css`
    @media ${mq.and(breakpoints.gt_sm)} {
      width: 50%;
      flex-shrink: 1;
    }
  `,
  Icon: css`
    @media ${mq.and(breakpoints.sm)} {
      transform: rotate(90deg);
    }
  `
}
